import './App.css';

function App() {
  return (
    <div className="App">
     <h1>Ultimo, ultimo, ultimo y nos vamos</h1>
    </div>
  );
}

export default App;
